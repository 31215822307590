<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body text-center">
        <h5 class="col-12 col-lg-6 c">
          للتواصل عبر الواتسآب
          <br />
          <br />
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=966580200670&amp;text&amp;app_absent=0"
            class="btn btn-success btn-block"
          >
            <i class="fa fa-whatsapp"></i>
            966580200670
          </a>
        </h5>
      </div>
      <div class="card card-body text-center" v-if="!user.reseller_mode">
        <h5 class="col-12 col-lg-8 c text-left">
          هل تريد تفعيل وضع المسوقين؟
          <br /><br />
          قم بدعوة الاشخاص للتسجيل في النظام عبر الرابط الخاص بك واحصل على 10%
          من جميع المدفوعات التي تتم من خلال العميل لمدى الحياة. او يمكنك
          استخدام العائد للإشتراك في النظام بدون دفع اي رسوم مع خصم 50% على
          الباقات اذا قمت بالدفع من الرصيد الخاص بك كمسوق ولن يتم محاسبتك على
          ضريبة القيمة المضافة.
          <br />
          <br />
          <button
            @click="resellerMode()"
            class="btn btn-outline-success btn-block"
          >
            <i class="fa fa-check-square"></i>
            تفعيل وضع المسوقين الآن
          </button>
        </h5>
      </div>
      <div class="card card-body text-center" v-if="user.reseller_mode">
        <h5 class="col-12 col-lg8 c">
          انت الآن في وضع المسوقين.. يمكنك الذهاب لصفحة التسويق من خلال القائمة.
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    resellerMode() {
      var g = this;
      $.post(api + "/user/reseller/activate", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          location.reload();
        })
        .catch(function () {
          alert("حدث خطأ اثناء الاتصال بالخادم");
        });
    },
  },
};
</script>

<style>
</style>